<template>
    <div id="img">
        <div class="crumbs">查看图片</div>
        <div class="img">
            <el-button type="primary" @click="$router.back()" size="medium">点击返回上一页</el-button>
        </div>
        <div class="div">
            <div class="img" v-for="item in imgs" :key="item"><img :src="item" alt=""></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DemoImg',

    data() {
        return {
            imgs: []
        };
    },
    mounted() {
        this.imgs = this.$route.query.img.split(",")
    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
#img {
    width: 100vw;
    height: 100vh;
}

.crumbs {
    padding: 5px 20px;
    background-color: rgb(240, 240, 240);
}

.div {
    padding: 5px 20px;
}

.img {
    text-align: center;
    margin: 10px 0;
}
</style>